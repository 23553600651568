<template>
  <div class="chapter" :class="editable ? 'right-pane' : 'left-pane'">
    <div class="chapter-content-container panel panel-default">
      <div class="panel-body">
        <div class="chapter-header-container" @click="handleClick">
          <div v-if="!editMode" @click="chapterToggle">
            <h2>
              <div>{{ chapter.name }}</div>
              <k-label v-if="chapter.label" :label="chapter.label"></k-label>
              <button v-if="editable" class="edit-btn" title="Edit" aria-label="Edit">
                <i class="fas fa-edit" @click="editMode = true"></i>
              </button>
              <i v-if="!editable" class="fas" :class="`${showChapterContent ? 'fa-angle-up' : 'fa-angle-down'}`"></i>
            </h2>
            <p v-if="chapter.summary && editable" class="chapter-summary-text"><strong>Summary:</strong> {{ chapter.summary }}</p>
          </div>
          <form v-else class="edit-chapter">
            <div class="button-group">
              <button type="button" @click="cancelEdit" class="btn btn-outlined cancel-edit-btn">
                Cancel
              </button>
              <button
                type="button"
                @click="updateChapterMeta"
                class="btn btn-success save-btn"
                :disabled="invalidTitle || !hasChanges"
              >
                Save Changes
              </button>
            </div>
            <fieldset class="form-entry">
              <input v-model="chapterTitle" maxlength="100" placeholder="Enter title here..." class="form-input"/>
              <k-dropdown class="dropdown dark" placeholder="Select a Label"
              :showSearch="false"
              :options="chapterLabelOptions"
              :clear-option=true
              v-model="chapterLabel">
              </k-dropdown>
          </fieldset>
          <fieldset class="form-entry chapter-summary">
            <label for="chapter-summary">Summary</label>
            <textarea v-model="chapterSummary" placeholder="Enter summary here..." class="form-input"></textarea>
          </fieldset>
          </form>
        </div>
        <div v-if="editable && isChapterSelected" class="chapter-instruction">
          <p>Single click on an asset to remove it from the chapter, click and drag asset to re-arrange order.</p>
        </div>
        <ol v-if="showChapterContent">
          <draggable
            v-model="chapter.assets"
            draggable=".asset-items"
            ghost-class="ghost"
            @change="$emit('assets-reordered', chapter.assets)"
            :disabled="!isChapterSelected">
            <asset-item v-for="asset in chapter.assets" class="asset-items"
              :key="`${asset.asset_type}-${asset.id}`"
              :asset="asset"
              :interaction-enabled="assetInteractionEnabled"
              :is-chapter-selected="isChapterSelected"
              :selected-override="overrideSelection(asset)"
              @asset-click="$emit('select-asset', asset)"
            >
            </asset-item>
          </draggable>
        </ol>
      </div>
    </div>
    <div v-if="editable" class="chapter-icon-container">
      <k-tooltip text="Delete chapter">
        <button class="btn btn-danger delete-btn" @click="$emit('delete-chapter')" title="Delete chapter" aria-label="Delete chapter">
          <i class="fas fa-trash-alt"></i>
        </button>
      </k-tooltip>
      <i class="fas fa-grip-vertical"></i>
    </div>
  </div>
</template>

<style>
.ghost .item.release-option {
  border: 4px solid var(--kate-primary);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>

<style scoped>
.chapter {
  position: relative;
  padding: 15px 5px;
  display: flex;
  gap: 15px;
}

.chapter h2 {
  margin: 0;
  padding-bottom: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 15px;
}

.chapter-instruction p {
  font-weight: 400;
  text-align: left;
}

.chapter ol {
  padding: 0;
}

fieldset.form-entry {
  min-width: 100%;
}

.form-entry input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  margin: 5px 0;
  text-overflow: ellipsis;
  color: var(--kate-type-light);
  background-color: var(--input-background);
  border: var(--input-border);
}

.chapter-summary textarea {
  width: 100%;
  height: 100px;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--input-background);
  border: var(--input-border);
}

.edit-chapter {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.chapter-content-container {
  flex: 1;
}

.chapter-icon-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  text-align: center;
}

.chapter-header-container {
  display: flex;
  justify-content: space-between;
}

.chapter-icon-container button,
.chapter-header-container button {
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  font-size: 16px;
  padding: 5px 10px;
}

.chapter-header-container h2 button.edit-btn {
  padding: 10px;
}

.chapter-header-container h2 button.edit-btn:hover {
  color: var(--kate-primary);
}

.chapter-header-container button.save-btn {
  color: var(--kate-type-dark);
  background-color: var(--kate-success);
}

.chapter-header-container button.save-btn:hover {
  background-color: var(--kate-success-dark);
}

.chapter-icon-container button.delete-btn i {
  color: var(--kate-danger-alt);
}

.chapter-icon-container button.delete-btn:hover i {
  color: var(--kate-danger);
}

span.chapter-label {
  font-family: hk-grotesk-light, sans-serif;
  font-size: 0.5em;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  background-color: var(--kate-button-primary);
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.button-group .btn {
  font-size: 14px;
  padding: 8px 25px;
}

</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import KTooltip from '@base-components/k-tooltip.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import KLabel from '@base-components/k-label.vue';
import AssetItem from './asset-item.vue';

export default {
  components: {
    AssetItem,
    draggable: VueDraggableNext,
    KTooltip,
    KDropdown,
    KLabel,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    summary: {
      type: String,
      required: false,
    },
    assets: {
      type: Array,
      required: true,
    },
    editable: Boolean,
    assetInteractionEnabled: Boolean,
    isChapterSelected: Boolean,
    defaultChapterTitles: {
      type: Array,
      default: () => [],
    },
    disabledAssets: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chapter: {},
      editMode: false,
      showChapterContent: true,
      chapterTitle: '',
      chapterLabel: '',
      chapterSummary: '',
      chapterLabelOptions: [
        'Trainer Led',
        'Self Study',
        'Group Project',
      ],
    };
  },

  mounted() {
    this.chapter = {
      name: this.name, label: this.label, summary: this.summary, assets: this.assets,
    };
    this.chapterTitle = this.chapter.name;
    this.chapterLabel = this.chapter.label;
    this.chapterSummary = this.chapter.summary;
  },

  watch: {
    name() {
      this.chapter.name = this.name;
      this.chapterTitle = this.chapter.name;
    },
    label() {
      this.chapter.label = this.label;
      this.chapterLabel = this.chapter.label;
    },
    summary() {
      this.chapter.summary = this.summary;
      this.chapterSummary = this.chapter.summary;
    },
    assets() {
      this.chapter.assets = this.assets;
    },
    chapter() {
      this.$emit('input', this.chapter);
    },
  },

  computed: {
    invalidTitle() {
      if (!this.editable) {
        return undefined;
      }
      return !this.name || this.defaultChapterTitles.indexOf(this.name) !== -1;
    },
    hasChanges() {
      return this.chapterTitle !== this.chapter.name || this.chapterLabel !== this.chapter.label || this.chapterSummary !== this.chapter.summary;
    },
  },

  methods: {
    updateChapterMeta() {
      this.chapter.name = this.chapterTitle;
      this.editMode = false;
      this.chapter.label = this.chapterLabel;
      this.chapter.summary = this.chapterSummary;
      this.$emit('update-chapter-meta', { name: this.chapterTitle, label: this.chapterLabel, summary: this.chapterSummary });
    },
    handleClick() {
      if (!this.editMode) {
        this.$emit('select-chapter');
      }
    },
    chapterToggle() {
      if (!this.editable) {
        this.showChapterContent = !this.showChapterContent;
      }
    },
    overrideSelection(asset) {
      return Boolean(this.disabledAssets.find(a => a.id === asset.id && a.asset_type === asset.asset_type));
    },
    cancelEdit() {
      this.editMode = false;
    },
  },
};
</script>
