<template>
  <div v-if="chapter.assets.length >= 1" class="chapter-container panel">
    <div @click="showSectionContent = !showSectionContent" class="chapter-header clickable">
      <div class="chapter-header-details">
        <div class="chapter-title-summary-container">
          <h4>
            <b v-if="isCustomChapter" class="number">{{ chapter.number }}. </b>
          <i v-else class="fas k-asset-icon" :class="getDefaultChapterIcon(chapter.name)"></i>
            <b class="title">{{ chapter.name }}</b>
          </h4>
          <p v-if="chapter.short_description" class="chapter-short-description">{{ chapter.short_description }}</p>
        </div>
        <k-progress v-if="mandatoryAssets.length > 0"
          shape="bar"
          :percentage="chapterProgress"
          :precision="1"
          :show-bar-text="true"
          :valueMax="chapter.assets.length"
          customText="Completed"
          progress-theme="dark"
          ariaLabel="Overall Chapter Completion"
          size="small"
        ></k-progress>
      </div>
      <i class="toggle fas" :class="toggleClass"></i>
    </div>
    <div v-if="showSectionContent" class="chapter-asset">
      <div v-if="showSectionContent" class="chapter-assets">
        <table class="chapter-assets-table">
          <tbody>
            <tr v-for="(asset, index) in assets" :key="asset.number" :class="assetDetailsClass(asset.asset_type)">
              <!-- Asset details -->
              <td class="asset-icon">
                <i class="fas k-asset-icon" :class="getAssetIcon(asset.asset_type)"></i>
              </td>
              <td class="asset-title">
                <router-link v-if="hasSubmissions(index)" class="asset-title-link" :to="getFeedbackPath(index)">
                  {{ asset.name }} <i class="fas fa-external-link-alt"></i>
                </router-link>
                <span v-else>{{ asset.name }}</span>
                <k-asset-tags :asset="asset"></k-asset-tags>
              </td>
              <!-- Meeting details / form -->
              <template v-if="asset.asset_type === 'meeting'">
                <td v-if="meetingInEditMode(index)" class="form-entry table-calendar">
                  <k-date-picker
                      class="meeting-date-picker"
                      mask="MMM D, YYYY"
                      type="datetime"
                      v-model="meetingDate"
                      :modelConfig="{ timeAdjust: '00:00:00' }">
                  </k-date-picker>
                </td>
                <td v-if="meetingInEditMode(index)" class="form-entry table-calendar">
                  <k-date-picker
                      class="meeting-date-picker"
                      mask="MMM D, YYYY"
                      type="datetime"
                      v-model="meetingEnd"
                      :modelConfig="{ timeAdjust: '00:00:00' }">
                  </k-date-picker>
                </td>
                <td v-else class="asset-date">
                  <span>{{ eventDateText(index) }}</span>
                </td>
                <td v-if="meetingInEditMode(index)" class="form-entry table-input">
                  <fieldset class="form-entry">
                    <input placeholder="Enter meeting link" class="meeting-link-input" v-model="meetingLink"/>
                  </fieldset>
                </td>
                <td class="asset-meeting-link">
                  <a :href="asset.meeting_link">{{ asset.meeting_link }}</a>
                </td>
              </template>
              <td v-if="asset.asset_type === 'calendar_event'" class="asset-date" >
                <span>{{ eventDateText(index) }}</span>
              </td>
              <!-- Check and Score -->
              <td v-if="asset.is_complete && asset.asset_type !== 'meeting'">
                <i class="fas fa-check"></i>
              </td>
              <td v-if="asset.progress_score || asset.latest_score" class="asset-score">
                <span :class="progressColour(index)">
                  {{ scoreFormat(index) }} %
                </span>
              </td>
                <!-- Table actions -->
              <template v-if="asset.asset_type === 'meeting'">
                <td class="table-edit-btn table-action">
                  <button class="table-action-btn" :class="meetingEditBtnClass(index)"
                    aria-label="Toggle" title="Toggle" @click="toggleMeetingEdit(index)" :disabled="!editMeetingAllowed"
                      v-html="meetingEditBtnVal(index)"
                    >
                  </button>
                </td>
                <td class="table-action">
                  <g-cal
                  v-if="isLearnerSupport"
                  :disable="meetingInEditMode(index) || !asset.meeting_start"
                  @updated="$emit('update-chapters')"
                  :module-meeting-id="asset.id"
                  :user-id="userId"
                  :event-link="asset.google_calendar_event_link"
                  class="table-action-btn"
                  ></g-cal>
                </td>
                <td class="table-action">
                  <button class="table-action-btn" :class="meetingCompleteBtnClass(index)" aria-label="Toggle" title="Toggle" @click="toggleMeetingCompletion(index)" :disabled="!editMeetingAllowed">
                    {{ meetingCompleteBtnVal(index) }}
                  </button>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <k-modal :show="showMeetingConfirmModal" @close="closeMeetingConfirmModal">
      <template #header>
        <h2>Updating Meeting?</h2>
      </template>
      <template #body>
        This meeting will also be updated on Google Calendar
      </template>
      <template #footer>
        <button @click="confirmUpdateMeeting" class="meeting-confirmation-btn btn btn-success">Confirm</button>
        <button @click="closeMeetingConfirmModal" class="meeting-confirmation-cancel-btn btn btn-danger">Cancel</button>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.chapter-container {
  margin: 15px;
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease-in-out;
}

.chapter-container:hover {
  background-color: var(--kate-background-alt-alpha);
}

.chapter-container:active {
  box-shadow: var(--box-shadow);
  background-color: var(--kate-background-alt-alpha);
}

.chapter-header {
  padding: 15px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.chapter-title-summary-container {
  display: flex;
  flex-direction: column;
}

.chapter-header-details {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  flex: 1;
}

.chapter-header .k-progress.squared {
  min-width: 140px;
}

.chapter-header-details .k-progress {
  width: 30%;
}

.chapter-asset .fa-check {
  color: var(--kate-success);
}

.chapter-asset {
  padding: 15px;
}

.asset-score span {
  color: var(--kate-type-dark);
  padding: 3px 5px;
  border-radius: 4px;
  text-align: center;
}

.asset-score .success {
  background-color: var(--kate-success);
}

.asset-score .warning {
  background-color: var(--kate-warning);
}

.asset-entry.k-asset-icon {
  margin-right: 5px;
}

.asset-score .danger {
  background-color: var(--kate-danger);
}

.table-action {
  text-align: right;
}

.table-action-btn {
  font-size: 0.75em;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.meeting-link-input {
  width: 100%;
  min-width: 100px;
  height: 37px !important;
  border-radius: 4px;
}

.tags {
  width: fit-content;
  display: inline-block;
  margin-left: 10px;
}

/* Table */
.chapter-assets {
  overflow-x: auto;
}

.chapter-assets-table {
  width: 100%;
  border-collapse: collapse;
}

.chapter-assets-table td {
  padding: 5px;
}

.chapter-assets-table tr:hover {
  background-color: var(--kate-background-body-alpha);
}

.asset-icon {
  width: 20px;
}

.asset-title {
  width: 25vw;
  min-width: 300px;
}

td.asset-date,
td.asset-meeting-link,
td.form-entry.table-input,
td.form-entry.table-calendar {
  min-width: 190px;
}

.chapter-asset-details .asset-score {
  width: 70px;
  text-align: center;
}

</style>

<script>
import useGlobalStore from '@stores/global';
import GoogleOAuthMixin from '@mixins/google-oauth-login-mixin';
import ModuleChapterPanelMixin from '@mixins/single-chapter-mixin';
import KProgress from '@base-components/k-progress.vue';
import KAssetTags from '@base-components/k-asset-tags.vue';
import timeMixins from '@mixins/time-mixins';
import KDatePicker from '@base-components/k-date-picker.vue';
import errorMixins from '@mixins/error-mixins';
import KModal from '@base-components/k-modal.vue';
import AddToGCal from './add-to-gcal.vue';
import { ASSET_TYPES } from '../../../constants';

export default {
  components: {
    KProgress,
    KAssetTags,
    'k-date-picker': KDatePicker,
    'g-cal': AddToGCal,
    KModal,
  },

  mixins: [GoogleOAuthMixin, ModuleChapterPanelMixin, timeMixins, errorMixins],

  props: {
    chapter: Object,
    userId: Number,
    programmeMemberId: Number,
    isLearnerSupport: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      updateInProgress: false,
      showSectionContent: true,
      meetingEditing: [],
      meetingDate: undefined,
      meetingEnd: undefined,
      meetingLink: undefined,
      meetingComplete: undefined,
      showMeetingConfirmModal: false,
      meetingConfirmIndex: undefined,
    };
  },

  computed: {
    editMeetingAllowed() {
      const mentees = this.store.userMentees.map(m => m.programme_member_id);
      const manageUserMeetings = this.store.userPermissions.indexOf('manage_user_meetings') !== -1;
      return mentees.indexOf(this.programmeMemberId) !== -1 || manageUserMeetings;
    },
  },

  methods: {
    closeMeetingConfirmModal() {
      this.showMeetingConfirmModal = false;
    },
    openMeetingConfirmModal() {
      this.showMeetingConfirmModal = true;
    },
    assetDetailsClass(assetType) {
      if (assetType === 'meeting') {
        return 'chapter-asset-details meeting';
      }
      return 'chapter-asset-details';
    },
    eventDateText(index) {
      if (this.assets[index].meeting_start) {
        let dateStr = this.parseTimestamp(this.assets[index].meeting_start);
        if (this.assets[index].meeting_end) {
          dateStr += ` - ${this.parseTimestamp(this.assets[index].meeting_end)}`;
        }
        return dateStr;
      }
      if (this.assets[index].event_start) {
        return this.parseTimestamp(this.assets[index].event_start);
      }
      return 'Not yet scheduled';
    },
    meetingInEditMode(index) {
      return this.meetingEditing.indexOf(index) !== -1;
    },
    editMeeting(index) {
      this.meetingEditing.push(index);
      this.meetingDate = this.assets[index].meeting_start;
      this.meetingEnd = this.assets[index].meeting_end;
      this.meetingLink = this.assets[index].meeting_link;
    },
    confirmUpdateMeeting() {
      this.closeMeetingConfirmModal();
      this.saveMeeting(this.meetingConfirmIndex, true);
    },
    saveMeeting(index, allowOverwriteGoogle = false) {
      const meeting = this.assets[index];
      if (meeting.google_calendar_event_id && !allowOverwriteGoogle) {
        this.meetingConfirmIndex = index;
        this.openMeetingConfirmModal();
      } else {
        this.meetingEditing.splice(this.meetingEditing.indexOf(index), 1);
        this.updateMeeting(meeting.id);
      }
    },
    toggleMeetingEdit(index) {
      if (!this.meetingInEditMode(index)) {
        this.editMeeting(index);
      } else {
        this.saveMeeting(index);
      }
    },
    toggleMeetingCompletion(index) {
      this.setCompletionDate(this.assets[index].id, this.assets[index].is_complete);
    },
    meetingEditBtnClass(index) {
      if (this.meetingInEditMode(index)) {
        return 'btn-success';
      }
      return 'btn-primary';
    },
    meetingEditBtnVal(index) {
      if (this.meetingInEditMode(index)) {
        return 'Save <i class="fa-solid fa-check"></i>';
      }
      return 'Edit <i class="fa-duotone fa-pencil"></i>';
    },

    meetingCompleteBtnClass(index) {
      if (this.assets[index].is_complete) {
        return 'btn-danger';
      }
      return 'btn-success';
    },
    meetingCompleteBtnVal(index) {
      if (this.assets[index].is_complete) {
        return 'Mark Incomplete';
      }
      return 'Mark Complete';
    },

    hasSubmissions(index) {
      if (ASSET_TYPES[this.assets[index].asset_type]?.completion_type === 'submission') {
        return this.assets[index].latest_timestamp !== null;
      }
      return false;
    },

    progressColour(index) {
      if (this.assets[index].latest_score > 75) {
        return 'success';
      }
      if (this.assets[index].latest_score < 30) {
        return 'danger';
      }
      return 'warning';
    },

    scoreFormat(index) {
      return (Math.round(10 * this.assets[index].latest_score)) / 10;
    },

    getFeedbackPath(index) {
      let output = {};
      if (this.assets[index].asset_type === 'pak') {
        output = {
          name: 'dash_pak_ov_feedback',
          params: {
            studentId: this.userId,
            modulePakId: this.assets[index].id,
          },
        };
      } else if (this.assets[index].asset_type === 'quiz') {
        output = {
          name: 'dash_quiz_feedback',
          params: {
            studentId: this.userId,
            moduleQuizId: this.assets[index].id,
          },
        };
      } else if (this.assets[index].asset_type === 'questionnaire') {
        output = {
          name: 'dash_questionnaire_feedback',
          params: {
            studentId: this.userId,
            moduleQuestionnaireId: this.assets[index].id,
          },
        };
      }
      return output;
    },
    setCompletionDate(modMeetingId, complete) {
      this.$logger.info('Set completion date');
      this.$http.put(
        `/api/curriculum/admin/meeting/${modMeetingId}/programme-member/${this.programmeMemberId}/${complete ? 'incomplete' : 'complete'}`,
      ).then(() => {
        this.$logger.info('Successfully updated meeting');
        this.$emit('update-chapters');
      }).catch(err => {
        if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to set completion date', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to set completion date', undefined, err);
        }
        this.showError(err);
      });
    },
    updateMeeting(modMeetingId) {
      const payload = {
        meeting_start: this.formatDate(this.meetingDate),
        meeting_end: this.formatDate(this.meetingEnd),
        meeting_link: this.meetingLink,
      };
      this.$logger.info('Updating meeting', payload);
      this.$http.put(
        `/api/curriculum/admin/meeting/${modMeetingId}/user/${this.userId}`,
        payload,
      ).then(() => {
        this.$logger.info('Sucessfully updated meeting');
        this.$emit('update-chapters');
      }).catch(err => {
        if (err.response?.data?.err === 'invalid_oauth_creds') {
          this.goToGoogleSignIn();
        } else if (this.$http.isWarning(err)) {
          this.$logger.warn('There was an error while attempting to update meeting', undefined, err);
        } else {
          this.$logger.error('There was an error while attempting to update meeting', undefined, err);
        }
        this.showError(err);
      });
    },
  },
};
</script>
